import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import TopHeading from '../icons/topHeading'
import ConceptHeading from '../icons/conceptHeading'
import PointHeading from '../icons/pointHeading'
import CourseHeading from '../icons/courseHeading'
import FaqHeading from '../icons/faqHeading'
import AccessHeading from '../icons/accessHeading'
import * as styles from '../styles/header.module.scss'

export default function Header({menuOpened, setMenuOpened}) {
  const menuList = [
    {
      icon: <TopHeading />,
      text: 'トップページ',
      link: '/'
    },
    {
      icon: <ConceptHeading />,
      text: 'コンセプト',
      link: '/#concept'
    },
    {
      icon: <PointHeading />,
      text: '選ばれる理由',
      link: '/#point'
    },
    {
      icon: <CourseHeading />,
      text: 'コース一覧',
      link: '/#course'
    },
    {
      icon: <FaqHeading />,
      text: 'よくある質問',
      link: '/#faq'
    },
    {
      icon: <AccessHeading />,
      text: '店舗情報',
      link: '/#access'
    }
  ]

  return (
    <header className={`${styles.header_wrapper} ${menuOpened ? styles.opened : ''}`}>
      <div className={styles.header_container}>
        <button
          className={styles.header_button}
          onClick={() => setMenuOpened(!menuOpened)}
        >
          <span className={`${styles.header_button_bar} ${menuOpened ? styles.opened : ''}`}></span>
          <span className={`${styles.header_button_bar} ${menuOpened ? styles.opened : ''}`}></span>
          <span className={styles.header_button_txt}>menu</span>
        </button>
      </div>

      <nav className={`${styles.header_menu} ${menuOpened ? styles.opened : ''}`}>
        <ul className={styles.menu_list}>
          { menuList.map((item, idx) => 
            <li className={styles.menu_item} key={idx} onClick={() => setMenuOpened(!menuOpened)}>
              <AnchorLink to={item.link} className={styles.menu_link}>
                <span className={styles.menu_icon}>{item.icon}</span>
                <span className={styles.menu_text}>{item.text}</span>
              </AnchorLink>
            </li>
          ) }
        </ul>
      </nav>
    </header>
  )
}
