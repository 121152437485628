import React, {useState} from 'react'
import Header from './header'
import Footer from './footer'
import './reset.css'
import './style.css'
import '@fontsource/noto-sans-jp'

export default function Layout({ children }) {
  const [menuOpened, setMenuOpened] = useState(false)

  return (
    <>
      <Header
        menuOpened={menuOpened}
        setMenuOpened={setMenuOpened}
      />
      <main>
        {children}
      </main>
      <Footer />
    </>
  )
}
