import React from 'react'
import {Link} from 'gatsby'
import * as styles from '../styles/footer.module.scss'
import LogoWhite from '../icons/logoWhite'

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <Link to='/' className={styles.footer_logo}>
        <LogoWhite />
      </Link>
      <small className={styles.copyright}>&copy; 2021 Party's Salon</small>
    </footer>
  )
}
