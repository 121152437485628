// extracted by mini-css-extract-plugin
export const header_wrapper = "header-module--header_wrapper--3v848";
export const opened = "header-module--opened--23LMC";
export const header_container = "header-module--header_container--3HKvD";
export const header_button = "header-module--header_button--2kP2i";
export const header_button_bar = "header-module--header_button_bar--3nKs7";
export const header_button_txt = "header-module--header_button_txt--VeR7G";
export const header_menu = "header-module--header_menu--1Uw8F";
export const menu_list = "header-module--menu_list--3QuJk";
export const menu_item = "header-module--menu_item--3FeV8";
export const menu_link = "header-module--menu_link--1BhRH";
export const menu_icon = "header-module--menu_icon--HReV_";
export const menu_text = "header-module--menu_text--3V5xr";