import React from 'react'
import ReserveIcon from '../icons/reserve'
import * as styles from '../styles/reserveButton.module.scss'

export default function ReserveButton() {
  return (
    <div className={styles.reserve_button_container}>
      <p className={styles.top_text}>ご予約はこちらから</p>
      <a className={styles.button_link} href="https://lin.ee/GzhFxAbr" target="_blank" rel="noopener noreferrer">
        <div className={styles.button_link_text}>
          <ReserveIcon height={'100%'} />
        </div>
      </a>
      <p className={styles.bottom_text}>ご予約専用 公式LINEにリンクします</p>
    </div>
  )
}
