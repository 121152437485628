import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

export default function Seo({ pagetitle, pagedescription }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const title = pagetitle
    ? `${pagetitle}｜${data.site.siteMetadata.title}`
    : data.site.siteMetadata.title

  const description = pagedescription || data.site.siteMetadata.description

  return (
    <Helmet>
      <meta name="theme-color" content="#7ab2a7" />
      <meta name="format-detection" content="telephone=no" />
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}
